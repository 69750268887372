import request from "../../utils/request";

// 查询列表
export function listExport(params) {
  return request({
    url: '/api/solar/exports/',
    method: 'get',
    params: params
  })
}


// 查询单个详情
export function getExport(exportId) {
  return request({
    url: `/api/solar/exports/${exportId}/`,
    method: 'get',
  })
}


// 新增
export function postExport(data) {
  return request({
    url: '/api/solar/exports/',
    method: 'post',
    data: data,
  })
}


// 修改(全部修改)
export function putExport(exportId, data) {
  return request({
    url: `/api/solar/exports/${exportId}/`,
    method: 'put',
    data: data
  })
}


// 修改(单个字段修改)
export function patchExport(exportId, data) {
  return request({
    url: `/api/solar/exports/${exportId}/`,
    method: 'patch',
    data: data
  })
}


// 删除
export function deleteExport(exportId) {
  return request({
    url: `/api/solar/exports/${exportId}/`,
    method: 'delete'
  })
}


export function listCertificate(params) {
  return request({
    url: `/api/solar/exports/list_certificate/`,
    method: 'get',
    params: params,
  })
}


export function postCertificate(data) {
  return request({
    url: '/api/solar/exports/student_certificate/',
    method: 'post',
    data: data,
  })
}

export function getFile(url){
  return new Promise((resolve, reject) => {
    request({
    url: url,
    method: 'get',
    responseType: 'arraybuffer',
    timeout:1000000
  }).then( (data) => {
      resolve( data )
    }).catch( (error) => {
      reject(error.toString())
    })
  })
}