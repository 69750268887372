<!--@Time : 2022/1/11 16:07-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<v-row class="d-flex align-center justify-center">

      <v-col cols="2">
        <v-datetime-picker
            v-model="starting_time"
            :time-picker-props="timeProps"
            time-format="HH:mm:ss"
            label="Starting Time"
        ></v-datetime-picker>
      </v-col>

      <v-col cols="2">
        <v-datetime-picker
            v-model="ending_time"
            :time-picker-props="timeProps"
            time-format="HH:mm:ss"
            label="Ending Time"
        ></v-datetime-picker>
      </v-col>

      <v-col cols="2">
        <v-btn
            v-if="isShow"
            outlined
            small
            color="primary"
            @click="createExcelInTime"
        >
          Fetch
        </v-btn>
      </v-col>
    </v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-data-table
					:headers="headers"
					:items="desserts"
					:loading="loading"
					:options.sync="options"
					:server-items-length="totalDesserts"
					class="elevation-0"
				>
					<template v-slot:item.action="{ item }">
						<a :href="item.url" download style="text-decoration: none">
							<v-icon color="success" small class="mr-2"
								>mdi-download</v-icon
							>
						</a>
						<v-icon
							color="error"
							small
							@click="deleteCertificate(item)"
							>mdi-delete</v-icon
						>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import {
	deleteExport,
	listExport,
	postExport,
} from "../../../api/solar/export";
import { Message } from "element-ui";
import moment from "moment";

export default {
	name: "DataSheet",
	data() {
		return {
			isShow:
				this.$store.getters.groups === 1 ||
				this.$store.getters.groups === 3 ||
        this.$store.getters.groups === 4,
      starting_time: "2022-08-08 9:50:00",
      ending_time: "2022-08-14 10:10:00",
      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
			headers: [
				{ text: "#", value: "index" },
				{ text: "Category", value: "category" },
				{ text: "File Name", value: "filename" },
				{ text: "UpdateTime", value: "update_time" },
				{ text: "Action", value: "action" },
			],
			desserts: [],
			loading: false,
			totalDesserts: 0,
			options: {
				page: 1,
				itemsPerPage: 10,
			},
		};
	},

	watch: {
		options: {
			handler() {
				this.getDataFromApi();
			},
			deep: true,
		},
		"$store.getters.team": {
			handler() {
				this.getDataFromApi();
			},
		},
	},
	methods: {
		createExcelInTime() {
      if (this.starting_time == null || this.ending_time == null) {
        Message({
          showClose: true,
          message: "未选择时间",
          type: "error",
        });
        return;
      } else {
        this.starting_time = moment(this.starting_time).format("YYYY-MM-DD HH:mm:ss")
        this.ending_time = moment(this.ending_time).format("YYYY-MM-DD HH:mm:ss")
      }
			if (this.$store.getters.team) {
        this.loading = true
				postExport({
					team: this.$store.getters.team.id,
					type: "excel",
					start_time: this.starting_time,
					end_time: this.ending_time,
				}).then(() => {
          this.loading = false
					this.getDataFromApi();
					Message({
            showClose: true,
						message: "导出成功",
						type: "success",
					});
				}).catch(()=>{this.loading=false});
			} else {
				Message({
          showClose: true,
					message: "No team is selected",
					type: "warning",
				});
			}
		},
		deleteCertificate(item) {
			deleteExport(item.id).then(() => {
				this.getDataFromApi();
				Message({
          showClose: true,
					message: "Delete Successful",
					type: "success",
				});
			});
		},

		getDataFromApi() {
			if (this.$store.getters.team === null) {
				this.desserts = [];
				return;
			}
			this.loading = true;
			listExport({
				team: this.$store.getters.team.id,
				category: "excel",
				page: this.options.page, //当前页
				pagesize: this.pageSize(this.options.itemsPerPage), //每页大小
			}).then((res) => {
				this.totalDesserts = res["count"];
				this.desserts = [];
				res["results"].forEach((item, index) => {
					this.desserts.push({
						index: index + 1,
						id: item["id"],
						category: "excel",
						filename: item["filename"],
						update_time: item["update_time"],
						url: `${process.env.VUE_APP_BACKEND_URL}/${item["relative_path"]}`,
					});
				});
				this.loading = false;
			});
		},

		pageSize(pagesize) {
			if (pagesize === -1) {
				return 1000;
			} else return pagesize;
		},
	},
};
</script>

<style scoped>
</style>