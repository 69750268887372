<!--@Time : 2022/1/11 16:08-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="desserts"
			:loading="loading"
			:options.sync="options"
			:server-items-length="totalDesserts"
			class="elevation-0"
		>
			<template v-slot:item.action="{ item }">
				<div>
					<v-icon
						v-if="isShow"
						color="primary"
						small
						@click="exportCertificate(item)"
						class="mr-2"
						>mdi-database-export</v-icon
					>
					<a
						v-if="item.url"
						:href="item.url"
						target="_blank"
            style="text-decoration: none"
					>
						<v-icon color="success" small class="mr-2"
							>mdi-download</v-icon
						>
					</a>
					<v-icon
						v-if="item.url"
						color="error"
						small
						@click="deleteCertificate(item)"
						>mdi-delete</v-icon
					>
				</div>
			</template>
		</v-data-table>
		<v-btn v-if="isShow" style="margin-right: 20px" color="warning" small outlined @click="allExport">GENERATE All</v-btn>
    <v-btn v-if="isShow" color="primary" small outlined @click="allDownload">DOWNLOAD All</v-btn>
	</div>
</template>

<script>
import {
  deleteExport, getFile,
  listCertificate,
  postCertificate,
} from "@/api/solar/export";
import { Message } from "element-ui";
import JSZip from "jszip";
import FileSaver from 'file-saver'

export default {
	name: "Certificate",
	data() {
		return {
			isShow:
				this.$store.getters.groups === 1 ||
				this.$store.getters.groups === 3,
			headers: [
				{ text: "#", value: "index", width: 50 },
				{ text: "Name", value: "student", width: 200 },
				{ text: "Category", value: "category", width: 180 },
				{ text: "File Name", value: "filename", width: 180 },
				{ text: "Update Time", value: "update_time", width: 250 },
				{ text: "Action", value: "action", width: 180 },
			],
			desserts: [],
			loading: false,
			totalDesserts: 0,
			options: {
				page: 1,
				itemsPerPage: 10,
			},
		};
	},
	watch: {
		options: {
			handler() {
				this.getDataFromApi();
			},
			deep: true,
		},
		"$store.getters.team": {
			handler() {
        this.options.page = 1
				this.getDataFromApi();
			},
		},
	},
	methods: {
		// 导出Certificate
		exportCertificate(item) {
			this.loading = true;
			postCertificate({
				student: item["student_id"],
			}).then(() => {
				Message({
					message: "导出成功",
					type: "success",
				});
				this.getDataFromApi();
			});
			this.loading = false;
		},
		deleteCertificate(item) {
			this.loading = true;
			deleteExport(item.id).then(() => {
				this.getDataFromApi();
				Message({
					message: "Delete Successful",
					type: "success",
				});
			});
			this.loading = false;
		},
    allExport() {
      if (this.$store.getters.team){
        this.loading = true;
        Message({
          message: "正在导出，请勿关闭此页面",
          type: "warning",
        });
        postCertificate({
          team: this.$store.getters.team.id,
        }).then(() => {
          Message({
            message: "导出成功",
            type: "success",
          });
          this.getDataFromApi();
        });
        this.loading = false;
      }
      else {
        Message({
          message: "No team is selected",
          type: "warning",
        });
      }
    },

    allDownload(){

			listCertificate({
				team: this.$store.getters.team.id,
				pagesize: 2000
			}).then((res) => {
        Message({
          message: "正在下载",
          type: "warning",
        });
        const promises = []
        const cache = {}
        const zip = new JSZip()
				res["results"].forEach( (item) => {
					if (item["certificate"] !== null) {
            const url = `${window.location.origin}/${item["certificate"]["relative_path"]}`
            console.log(url)
            const promise = getFile(url).then( (data) => {
              // 下载文件, 并存成ArrayBuffer对象
              const file_name = item["certificate"]["filename"] // 获取文件名
              zip.file(file_name, data, { binary: true }) // 逐个添加文件
              cache[file_name] = data
            })
            promises.push(promise)
          }
				});
        Promise.all(promises).then( () => {
          Message({
            message: "下载完成",
            type: "success",
          });
          zip.generateAsync({type:"blob"}).then( (content) => { // 生成二进制流
            FileSaver.saveAs(content, `${this.$store.getters.team.name_en}.zip`) // 利用file-saver保存文件
          })
        })
      });
    },

		getDataFromApi() {
			if (this.$store.getters.team === null) {
				this.desserts = [];
				return;
			}
			this.loading = true;
			listCertificate({
				team: this.$store.getters.team.id,
				page: this.options.page, //当前页
				pagesize: this.pageSize(this.options.itemsPerPage), //每页大小
			}).then((res) => {
				this.totalDesserts = res["count"];
				this.desserts = [];
				res["results"].forEach((item, index) => {
					if (item["certificate"] !== null) {
						this.desserts.push({
							index: index + 1,
							student_id: item["id"],
							student: item["name_en"],
							id: item["certificate"]["id"],
							category: "certificate",
							filename: item["certificate"]["filename"],
							update_time: item["certificate"]["update_time"],
							url: `${process.env.VUE_APP_BACKEND_URL}/${item["certificate"]["relative_path"]}`,
						});
					} else {
						this.desserts.push({
							index: index + 1,
							student: item["name_en"],
							student_id: item["id"],
						});
					}
				});
				this.loading = false;
			});
		},

		pageSize(pagesize) {
			if (pagesize === -1) {
				return 1000;
			} else return pagesize;
		},
	},
};
</script>

<style scoped>
</style>