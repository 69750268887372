<!--@Time : 2021/11/16 10:56-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-tabs v-if="isShow" v-model="tabs" centered fixed-tabs icons-and-text>
					<v-tabs-slider></v-tabs-slider>
					<v-tab>
						DataSheets
						<v-icon>mdi-file-excel</v-icon>
					</v-tab>

					<v-tab>
						Certificates
						<v-icon>mdi-file-certificate-outline</v-icon>
					</v-tab>

					<v-tab>
						Transcripts
						<v-icon>mdi-file-pdf-box</v-icon>
					</v-tab>
				</v-tabs>
        <v-tabs v-else v-model="tabs" centered fixed-tabs icons-and-text>
					<v-tabs-slider></v-tabs-slider>
					<v-tab>
						DataSheets
						<v-icon>mdi-file-excel</v-icon>
					</v-tab>
				</v-tabs>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-tabs-items v-if="isShow" v-model="tabs">
					<v-tab-item>
						<DataSheet></DataSheet>
					</v-tab-item>
					<v-tab-item>
						<Certificate></Certificate>
					</v-tab-item>
					<v-tab-item>
						<Transcript></Transcript>
					</v-tab-item>
				</v-tabs-items>
        <v-tabs-items v-else v-model="tabs">
					<v-tab-item>
						<DataSheet></DataSheet>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Header from "@/components/Header";
import DataSheet from "./DataSheet";
import Certificate from "./Certificate";
import Transcript from "./Transcript";
export default {
	name: "Export",
	components: { Transcript, Certificate, DataSheet, Header },
	data() {
		return {
      tabs: 0,
      isShow:
          this.$store.getters.groups === 1
    };
  }
};
</script>

<style scoped>
</style>