<!--@Time : 2022/1/11 16:08-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<v-row>
		<v-col cols="12" md="12" lg="12">
			<v-data-table
				:headers="headers"
				:items="desserts"
				:loading="loading"
				:options.sync="options"
				:server-items-length="totalDesserts"
				class="elevation-0"
			>
				<template v-slot:item.action="{ item }">
					<div>
            <a
                style="text-decoration: none"
                :href="item.url"
                target="_blank"
                download
            >
							<v-icon color="success" small class="mr-2"
								>mdi-download</v-icon
							>
						</a>
						<v-icon
							color="error"
							small
							@click="deleteCertificate(item)"
							>mdi-delete</v-icon
						>
					</div>
				</template>
			</v-data-table>

			<v-btn
				v-if="isShow"
				color="warning"
				small
				outlined
				@click="exportPdf"
				>Export</v-btn
			>
		</v-col>
	</v-row>
</template>

<script>
import {
	deleteExport,
	listExport,
	postExport,
} from "../../../api/solar/export";
import { Message } from "element-ui";

export default {
	name: "Transcript",
	data() {
		return {
			isShow:
				this.$store.getters.groups === 1 ||
				this.$store.getters.groups === 3,
			headers: [
				{ text: "#", value: "index" },
				{ text: "Category", value: "category" },
				{ text: "File Name", value: "filename" },
				{ text: "UpdateTime", value: "update_time" },
				{ text: "Action", value: "action" },
			],
			desserts: [],
			loading: false,
			totalDesserts: 0,
			options: {
				page: 1,
				itemsPerPage: 10,
			},
		};
	},
	watch: {
		options: {
			handler() {
				this.getDataFromApi();
			},
			deep: true,
		},
		"$store.getters.team": {
			handler() {
				this.getDataFromApi();
			},
		},
	},
	methods: {
    // 导出Certificate
    exportPdf() {
      if (this.$store.getters.team) {
        this.loading = true;
        postExport({
          team: this.$store.getters.team.id,
          type: "export",
        }).then(() => {
          Message({
            message: "导出成功",
            type: "success",
          });
          this.getDataFromApi();
        });
        this.loading = false;
      } else {
        Message({
          message: "No team is selected",
          type: "warning",
        });
      }
    },
		deleteCertificate(item) {
			this.loading = true;
			deleteExport(item.id).then(() => {
				this.getDataFromApi();
				Message({
					message: "Delete Successful",
					type: "success",
				});
			});
			this.loading = false;
		},
		getDataFromApi() {
			if (this.$store.getters.team === null) {
				this.desserts = [];
				return;
			}
			this.loading = true;
			listExport({
				team: this.$store.getters.team.id,
				category: "export",
				page: this.options.page, //当前页
				pagesize: this.pageSize(this.options.itemsPerPage), //每页大小
			}).then((res) => {
				this.totalDesserts = res["count"];
				this.desserts = [];
				res["results"].forEach((item, index) => {
					this.desserts.push({
						index: index + 1,
						id: item["id"],
						category: "export",
						filename: item["filename"],
						update_time: item["update_time"],
						url: `${process.env.VUE_APP_BACKEND_URL}/${item["relative_path"]}`,
					});
				});
				this.loading = false;
			});
		},

		pageSize(pagesize) {
			if (pagesize === -1) {
				return 1000;
			} else return pagesize;
		},
	},
};
</script>

<style scoped>
</style>